.progressbg {
  display: block;
  position: absolute;
  z-index: 2;
  left: 4px;
  bottom: 2px;
  height: 4px;
  width: 240px;
  border-width: 1px;
  border-color: #2C2C2C;
  border-style: solid;
  background: black;
  will-change: opacity;
  pointer-events: none; }

.progressfg {
  height: 4px;
  background: linear-gradient(to right, #189e1c, #22e328);
  width: 100%;
  border-radius: 2px;
  will-change: transform;
  transform-origin: left; }

.adsk-viewing-viewer .property-panel {
  top: 180px;
  left: 220px;
  width: 300px;
  height: 250px;
  min-width: 250px;
  min-height: 100px;
  border-color: transparent; }

.adsk-viewing-viewer .property-panel .treeview {
  margin-left: 0px;
  padding: 0;
  overflow: hidden; }

.adsk-viewing-viewer .property-panel .treeview .indented {
  left: 21px; }

.adsk-viewing-viewer .property-panel .treeview .indented > lmvheader > .category-value {
  padding-left: 10px; }

.adsk-viewing-viewer .property-panel .treeview .indented .expanded > lmvheader > .property-value {
  padding-left: 20px; }

.adsk-viewing-viewer .property-panel .treeview group {
  left: 0px;
  width: 100%; }

.adsk-viewing-viewer .property-panel .no-properties {
  display: inline-block;
  padding: 6px 6px 6px 12px;
  width: calc(100% - 18px); }

.adsk-viewing-viewer .property-panel .treeview leaf {
  margin-left: 0; }

.adsk-viewing-viewer .property-panel .category-name,
.adsk-viewing-viewer .property-panel .category-value,
.adsk-viewing-viewer .property-panel .property-name,
.adsk-viewing-viewer .property-panel .property-value,
.adsk-viewing-viewer .property-panel .property-drop-down {
  white-space: normal;
  vertical-align: middle; }

.adsk-viewing-viewer .property-panel .category-name {
  top: -4px;
  min-width: 40%;
  max-width: 100px;
  display: inline-block;
  position: relative;
  vertical-align: middle; }

.adsk-viewing-viewer .property-panel .category-value {
  top: -4px;
  font-style: oblique;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  font-weight: 200;
  padding-left: 20px; }

.adsk-viewing-viewer .property-panel .property-name {
  top: -4px;
  padding-left: 28px;
  width: 40%;
  display: table-cell;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  max-width: 10px;
  /* Stub value, small enough to work along with table-cell's percentage width */
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis; }

.adsk-viewing-viewer .property-panel .aggregate-name {
  padding-left: 20px;
  font-weight: 600;
  font-size: 14px; }

.adsk-viewing-viewer .property-panel .property-value {
  width: 60%;
  height: 100%;
  display: table-cell;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  font-weight: normal;
  vertical-align: middle;
  position: relative;
  padding-left: 40px; }

.adsk-viewing-viewer .property-panel .property-drop-down {
  margin-top: 3px;
  margin-left: 20px;
  height: 85%;
  max-width: 150px;
  min-width: 60%;
  border: none;
  border-radius: 5px;
  padding-left: 5px; }

.adsk-viewing-viewer .property-panel .separator {
  display: table-cell;
  vertical-align: middle;
  width: 1px; }

.adsk-viewing-viewer .property-panel .treeview lmvheader {
  padding-left: 13px;
  display: table; }

.adsk-viewing-viewer .property-panel .treeview group > lmvheader {
  padding-top: 15px;
  padding-bottom: 10px; }

.adsk-viewing-viewer .property-panel .treeview leaf > lmvheader {
  padding-top: 0;
  padding-bottom: 0; }

/* Turn off the hovering for non expandable items */
.adsk-viewing-viewer.dark-theme .treeview leaf > lmvheader:hover,
.adsk-viewing-viewer.dark-theme .treeview .leaf > lmvheader:hover {
  background: none !important; }

.adsk-icon-arrow:before {
  content: "a"; }

.adsk-icon-box:before {
  content: "b"; }

.adsk-icon-selection:before {
  content: "0"; }

.adsk-icon-bug:before {
  content: "c"; }

.adsk-icon-camera:before {
  content: "d"; }

.adsk-icon-explode:before {
  content: "e"; }

.adsk-icon-first-person:before {
  content: "f"; }

.adsk-icon-fit-to-view:before {
  content: "g"; }

.adsk-icon-fov:before {
  content: "h"; }

.adsk-icon-fullscreen:before {
  content: "i"; }

.adsk-icon-fullscreen-exit:before {
  content: "j"; }

.adsk-icon-game-controller:before {
  content: "k"; }

.adsk-icon-home:before {
  content: "l"; }

.adsk-icon-layers:before {
  content: "m"; }

.adsk-icon-section-analysis:before {
  content: "o"; }

.adsk-icon-roll:before {
  content: "p"; }

.adsk-icon-refresh:before {
  content: "q"; }

.adsk-icon-properties:before {
  content: "r"; }

.adsk-icon-plane-z:before {
  content: "s"; }

.adsk-icon-plane-y:before {
  content: "t"; }

.adsk-icon-plane-x:before {
  content: "u"; }

.adsk-icon-pan:before {
  content: "v"; }

.adsk-icon-orbit-constrained:before {
  content: "w"; }

.adsk-icon-measure-menu:before {
  content: "x"; }

.adsk-icon-measure:before {
  content: "S"; }

.adsk-icon-measure-distance-new:before {
  content: "T"; }

.adsk-icon-measure-angle-new:before {
  content: "U"; }

.adsk-icon-measure-area-new:before {
  content: "V";
  text-transform: uppercase !important; }

.adsk-icon-measure-arc-new:before {
  content: "Z"; }

.adsk-icon-measure-calibration:before {
  content: "X"; }

.adsk-icon-measure-settings:before {
  content: "Y"; }

.adsk-icon-measure-trash:before {
  content: "W";
  text-transform: uppercase !important; }

.adsk-icon-live-review:before {
  content: "y"; }

.adsk-icon-orbit-free:before {
  content: "z"; }

.adsk-icon-zoom:before {
  content: "A"; }

.adsk-icon-walk:before {
  content: "B"; }

.adsk-icon-structure:before {
  content: "C"; }

.adsk-icon-settings-render:before {
  content: "D"; }

.adsk-icon-second:before {
  content: "E"; }

.adsk-icon-first:before {
  content: "F"; }

.adsk-icon-measure-distance:before {
  content: "G"; }

.adsk-icon-settings:before {
  content: "n"; }

.adsk-icon-measure-angle:before {
  content: "H"; }

.adsk-icon-axis-x:before {
  content: "I"; }

.adsk-icon-axis-y:before {
  content: "J"; }

.adsk-icon-axis-z:before {
  content: "K"; }

.adsk-icon-axis-delta-x:before {
  /*content: "P";*/ }

.adsk-icon-axis-delta-y:before {
  /*content: "Q";*/ }

.adsk-icon-axis-delta-z:before {
  /*content: "R";*/ }

.adsk-icon-plus:before {
  content: "L"; }

.adsk-icon-mem-mgr:before {
  content: "O"; }
